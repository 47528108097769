.footer__main{
  margin-top: $spacing-xxl;
  border-top: 1px solid $bordergray;
  font-size: $font-size-sm;
  ul{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: $spacing-sm;
  }
  li{
    list-style-type: none;
  }
}
