
.btn {
  width: 100%;
  display: block;
  border:none;
  background-image:none;
  background-color:none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-weight: normal;
  text-decoration: none;
  text-align: left;
  text-decoration: none;
  padding: calc(#{$spacing-sm} * 0.67) calc(#{$grid-gutter-width} / 2);
  &::-moz-focus-inner{
    border: 0;
    padding: 0;
  }
  &.btn-primary {
    background-color:$blue;
    color: $white;
    border-color: $white;
    &:active {
      background-color: darken($blue, 5);
    }
  }
  &.btn-default {
    background-color: $blue-alt;
    color:$white;
    border-color: $blue;
    &:active {
      background-color: darken($blue-alt, 5);
    }
  }
  &.btn-cancel {
    background-color: $lightgray;
    color:$base-color;
    border-color: $base-color;
    &:active {
      background-color: darken($lightgray, 5);
    }
  }
  &.btn-small {
    padding: $spacing-xs calc(#{$grid-gutter-width} / 2);
  }
  &.btn-xs {
    padding: $spacing-xxs calc(#{$grid-gutter-width} / 2);
    width:auto;
    display: inline-block;
    text-align: center;
    border-radius:20px;
    @extend .ts-small;
  }
  &.btn-disabled {
    cursor:not-allowed;
    background-color:$gray;
    &:active {
      background-color: $gray;
    }
  }
}

.btn-container {
  display:flex;
  flex-direction: row;
  .btn {
    box-sizing: border-box;
    flex:1;
  }
  .btn-primary {
    flex:3;
  }
}
