.modal {
  display: none;
  z-index: 3;
  position: fixed;
  left: 0;
  top:0;
  width:100%;
  height:100%;
  background-color: $lightgray;
  overflow: scroll;
  @extend %font-size-md;
  .modal-body {
    @extend .wrapper;
    padding-top:$spacing-md;
  }
  img,
  video {
    max-width: 100%;
    height: auto;
    display: block;
  }
  .panel-heading {
    margin-top: $spacing-sm;
  }
  .panel-action {
    margin-bottom: $spacing-lg;
  }
}

.modal-dialog {
  .modal-content {
    width: 100%;
    height: 100vh;
    display: flex;
  }
  .modal-header {
    display: block;
    margin: auto;
    max-width: 100%;
    width: 350px;
  }
  .modal-title {
    @extend .ts-title;
    color: $base-color;
    margin-bottom: $spacing-md;
  }
  .btn {
    // margin-bottom: $spacing-xxs;
  }
}
