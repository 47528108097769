.link {
  color: $action;
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: $border-width-sm solid;
  &:hover {
    color: $action-response;
    border-color: $action-response;
    text-decoration: none;
  }
  &.link--delete {
    &:hover {
      color: $action-important;
      border-color: $action-important;
      text-decoration: none;
    }
  }
}

.link + .link {
  margin-left: $spacing-xs;
}

.link__back {
  display: block;
  border: none;
  margin-bottom: $spacing-xs;
}
