.panel {
  position:relative;
}

.panel-heading {
  &,
  input{
    @extend .ts-small;
  }
  .tab-select {
    @extend .ts-subtitle;
  }
  h2 {
    @extend .ts-subtitle;
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    display:inline;
  }
  padding-top: $spacing-xs;
  padding-bottom: $spacing-xs * 1.5;
}

.panel-body {
  label {
    color: $base-color;
    @extend .ts-label;
  }
  padding-left :calc(#{$grid-gutter-width} / 2);
  padding-right :calc(#{$grid-gutter-width} / 2);
  padding-top: $spacing-sm;
  padding-bottom: $spacing-sm;
  background-color: $white;
  &--medias {
    padding-left: 0;
  }
}

.panel-footer {
  @extend .ts-small;
  li {
    margin-right: 0;
    display: inline-block;
    &:not(:first-child)::before{
      content:'/';
      margin-right: $spacing-xxs;
    }
  }
  list-style: none;
  margin-top: $spacing-sm;
  margin-bottom: $spacing-md;
  color: $gray;
}

.panel-action {
  position:sticky;
  bottom:0;
  border-top: $border-width-md solid $lightgray;
  margin-top: $spacing-sm;
}
