.navbar__nav,
.navbar__list {
  display:flex;
}

.navbar {
  z-index: 2;
  background-color: $body-color;
  width:100%;
  overflow-x: auto;
  height:auto;
  border-bottom: $border-width-sm solid $lightgray;
  &.navbar--fixed-top {
    position:sticky;
    top:0;
  }
  a {
    color: $black;
    text-decoration: none;
    &:hover {
      color: $action-response;
      text-decoration: none;
    }
    line-height: 1;
    display: block;
    @extend %font-size-md;
  }
  &.nav--secondary {
    margin-top:-1px; //pour cacher la bordure de la nav--primary
    list-style: none;
    width:100%;
    background:$body-color;
    position:sticky;
    top:53px;
    display:none;
    .js-dropdown-menu {
      display:flex;
      @extend .wrapper;
      @include media(">=sm") {
        justify-content: flex-end;
      }
    }
    li {
      flex-shrink: 0;
    }
    a {
      color: $gray;
      padding-left: calc(#{$grid-gutter-width} / 3 );
      padding-right: calc(#{$grid-gutter-width} / 3 );
      padding-top: $spacing-sm;
      padding-bottom: $spacing-sm;
      &:hover,
      &.active {
        color:$action-response;
      }
    }
  }
}

.navbar__brand {
  position: absolute;
  left:calc(-30px - calc(#{$grid-gutter-width} * 0.67));
  display: block;
  top: 13px;
  @extend %fantom-small;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
  @include media("<sm") {
    margin-top: $spacing-xs;
    margin-right: calc(#{$grid-gutter-width} / 3 );
    position:static;
  }
}

.navbar__nav {
  position: relative;
  justify-content: space-between;
  @extend .wrapper;
  &:hover{
    .navbar__brand{
      @extend %fantom-small-uh;
    }
  }
}

.navbar__list {
  align-items: center;
  &.navbar__list--left {
    padding-right: $spacing-sm;
    flex:1;
    li:first-child{
      a {
        padding-left: 0;
      }
    }
    .active a{
      color: $blue;
    }
  }

  &, & li {
    flex-shrink: 0;
    list-style: none;
  }
  a {
    padding-top: $spacing-sm;
    padding-bottom: $spacing-sm;
    padding-left: calc(#{$grid-gutter-width} / 3 );
    padding-right: calc(#{$grid-gutter-width} / 3 );
  }
}

.lang__link {
  text-transform: capitalize;
}
