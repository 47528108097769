
.js-dropdown {
  position: relative;
}

.js-dropdown-content,
.js-dropdown-menu {
  display:none;
}

.js-dropdown-toggle{
  &.js-dropdown-active {
    .js-dropdown-icon {
      transform:rotate(180deg);
    }
  }
}

.select2-results__group{
  font-weight: bold;
}
