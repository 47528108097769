.login .main-content,
.reset_mail .main-content,
.reset_password .main-content {
    width: 50%;
    max-width: 1000px;
    min-height: calc(100vh - #{$spacing-sm});
    @include media("<sm") {
      width:80%;
    }
}

.login, .reset_mail, .reset_password {
  .panel {
    width:350px;
    @include media("<sm") {
      width:80%;
    }
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
  }
}

.remember {
  label {
    width: 50%;
    text-align: right;
  }
}

.login, .reset_mail, .reset_password {
  .form-group {
    margin-top: 0;
    > input {
      padding: $spacing-xs $spacing-xxs;
      width: 100%;
      margin:0 auto;
      border-bottom:none;
      border-top: $border-width-sm solid $lightgray !important;
      border-right: $border-width-md solid $blue !important;
      border-left:$border-width-md solid $blue !important;
    }
    input + input {
    }
  }
}
